import GreeveApiClient from "./client";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError, AxiosRequestConfig} from "axios";
import router from "@/router";
import useAuth from "@/composable/auth/useAuth";
import {ProfileInterface, UserInterface} from "@/greeve/user/user.interface";
import store from "@/store";
import useUser from "@/composable/greeve/useUser";
import useAssistant from '@/composable/greeve/useAssistant';

class AuthenticationError extends Error {
	errorCode: any;

	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

class BasicAuthError extends Error {
	errorCode: any;
	errorFields: any;

	constructor(errorCode: any, errorFields: any | undefined, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		if (errorFields != null) {
			this.errorFields = errorFields;
		}
		this.errorCode = errorCode;
	}
}

export interface GreeveAuthUserRegisterInterface {
	name: string;
	email: string;
	password: string;
}

export interface GreeveAuthUserLoginInterface {
	email: string;
	password: string;
}

export interface GreeveAuthUserForgotPasswordInterface {
	email: string;
}

export interface GreeveAuthUserSetNewPasswordInterface {
	hash: string;
	password: string;
}

export interface GreeveAuthUserVerifyInterface {
	hash: string;
}

const GreeveApiAuth = {
	getSignInUrl(): string {
		return process.env.VUE_APP_API + "/login";
	},

	signIn: async function (signInData: any) {
		try {
			const response = await GreeveApiClient.post('/login', signInData);
			if (response.data.data.token) {
				const token = response.data.data.token;
				const lifeTimeTimestamp = response.data.tokenLifeTime ?? useAuth().calculateTokenLifeTime();
				useAuth().saveToken(token);
				useAuth().saveRefreshToken(token);
				useAuth().setTokenLifeTime(lifeTimeTimestamp);
				GreeveApiClient.setHeader();
				useAssistant().resetGroups();
				return token;
			} else {
				throw new AuthenticationError(400, response.data.message ?? 'Unknown Signin Error');
			}
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	getUser: async function (): Promise<UserInterface | undefined> {
		try {
			const response = await GreeveApiClient.get("/user");
			if (response) {
				const userResponse = {} as UserInterface;
				const userProfile = {} as ProfileInterface;
				const responseData = response.data.data;
				if (responseData.profile) {
					const responseProfileData = responseData.profile;
					userProfile.id = responseProfileData.id;
					userProfile.user_id = responseProfileData.user_id;
					userProfile.country_code = responseProfileData.country_code;
					userProfile.language = responseProfileData.language;
					userProfile.first_name = responseProfileData.first_name;
					userProfile.last_name = responseProfileData.last_name;
					userProfile.username = responseProfileData.username;
					userProfile.degree = responseProfileData.degree;
					userProfile.gender = responseProfileData.gender;
					userProfile.birthday = responseProfileData.birthday;
					userProfile.job = responseProfileData.job;
					userProfile.profile_picture = responseProfileData.profile_picture;
					userProfile.hide_profile = responseProfileData.hide_profile;
					userProfile.hide_name = responseProfileData.hide_name;
					userProfile.beta = responseProfileData.beta;
					userProfile.data = responseProfileData.data ?? undefined;
					userProfile.updated_at = responseProfileData.updated_at;

					// const userAddress = {} as AddressType;
					// if (responseProfileData.address) {
					// 	//TODO
					// }
					// userProfile.address = userAddress;
				}
				userResponse.id = responseData.id;
				userResponse.email = responseData.email;
				userResponse.name = responseData.name;
				userResponse.email_verified_at = responseData.email_verified_at;
				if (responseData.avatarUrl) {
					userResponse.avatarUrl = responseData.avatarUrl;
				} else if (userProfile.profile_picture) {
					userResponse.avatarUrl = userProfile.profile_picture;
				} else {
					userResponse.avatarUrl = undefined;
				}
				userResponse.profile = userProfile;
				userResponse.state = responseData.state;
				userResponse.type = responseData.type;
				userResponse.updated_at = responseData.updated_at;
				return userResponse;
			}
		} catch (error) {
			this.catchError(error);
		}
	},

	async logout(redirectToStartPage = false, clearLocalStorage = true) {
		try {
			await GreeveApiClient.post("/logout", null);
			useAuth().removeToken();
			GreeveApiClient.removeHeader();
			GreeveApiClient.setHeader();
			GreeveApiClient.unmount401Interceptor();
		} catch (error) {
			console.error(error);
			useAuth().removeToken();
			GreeveApiClient.removeHeader();
			GreeveApiClient.setHeader();
			GreeveApiClient.unmount401Interceptor();
		} finally {
			if (clearLocalStorage) {
				store.reset();
				window.localStorage.clear();
			} else {
				useUser().resetUserData();
			}
			if (redirectToStartPage) {
				await router.push('/assistant/chat');
			}
		}

		return;
	},

	registerUser: async function (registerData: GreeveAuthUserRegisterInterface) {
		try {
			const response = await GreeveApiClient.put( "/register", registerData);
			const message = response.data.data.message;
			const token = response.data.data.token;
			const tokenLifeTime = response.data.data.tokenLifeTime ?? useAuth().calculateTokenLifeTime();
			console.log(response, message, "TOKEN;",  token)
			if (token) {
				useAuth().saveToken(token);
				useAuth().saveRefreshToken(token);
				useAuth().setTokenLifeTime(tokenLifeTime);
				GreeveApiClient.setHeader();
				useAssistant().resetGroups();
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown Signin Error');
			}
			GreeveApiClient.mount401Interceptor();
			return token;
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response?.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},

	resetPassword: async function (forgotPasswordData: GreeveAuthUserForgotPasswordInterface) {
		try {
			const response = await GreeveApiClient.post( "/reset/password", forgotPasswordData);
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown Forgot Password Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response?.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Forgot Password Error');
			} else {
				this.catchError(error);
			}
		}
	},

	resetAndVerifyNewPassword: async function (uuid: string, resetAndVerifyNewPasswordData: GreeveAuthUserSetNewPasswordInterface) {
		try {
			const response = await GreeveApiClient.post( "/user/" + uuid + "/password/reset", resetAndVerifyNewPasswordData);
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown Forgot Password Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response?.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Forgot Password Error');
			} else {
				this.catchError(error);
			}
		}
	},

	sendResetPasswordLink: async function (email: string) {
		try {
			const resetPasswordData = {email: email};
			const response = await GreeveApiClient.post("/reset/sendResetLink", resetPasswordData);
			if (response.status == 200) {
				return response;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown verify Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response?.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},

	hasValidResetToken: async function (resetPasswordData: any) {
		try {
			const response = await GreeveApiClient.post("/reset/forgot-password/checkToken", resetPasswordData);
			return !!response.data;
		} catch (error) {
			this.catchError(error);
		}
	},

	verifyAccount: async function (uuid: string, hash: string) {
		try {
			const response = await GreeveApiClient.post( "/account/verify", {uuid: uuid, hash: hash});
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown verify Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response?.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},


	resendVerifyLink: async function (uuid: string) {
		try {
			const response = await GreeveApiClient.post("/account/verify/resend", {uuid: uuid});
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown verify Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response?.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},

	signup: async function (email: any, password: any, name: any) {
		const signupData: AxiosRequestConfig = {
			method: "post",
			headers: {"Content-Type": "application/json"},
			url: "/oauth/signup",
			data: {
				email: email,
				password: password,
				name: name
			}
		};

		try {
			return await GreeveApiClient.customRequest(signupData);
		} catch (error) {
			this.catchError(error);
		}
	},

	catchError: function (error: any) {
		let status;
		let description;

		if (error.response === undefined) {
			status = error.message;
			description = error.message;
		} else {
			status = error.response.status;
			description = error.response.data?.error_description ?? error.message;
		}

		throw new AuthenticationError(status, description);
	}
}

export {GreeveApiAuth, AuthenticationError, BasicAuthError};
