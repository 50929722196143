
import {defineComponent, onBeforeMount, ref} from 'vue';
import useBlog from '@/composable/blog/useBlog';
import {Blog} from '@/greeve/blog/blog.type';
import useSystem from '@/composable/core/useSystem';
import {IonContent} from '@ionic/vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';

export default defineComponent({
	name: 'PostPage',
	components: {IonContent},
	props: {
		slug: {
			type: String,
		},
	},
	setup(props) {
		const {getBlogBySlug} = useBlog();
		const {getSeoManager, scrollToElement, getAnchorFromUrl} = useSystem();
		const post = ref<Blog>();
		const {headerHeight} = useHeaderHeight();

		const progressBar = ref<HTMLElement>();

		const onScroll = (event: any) => {
			if (!progressBar.value) {
				return;
			}

			const scrollTop = event.detail.scrollTop + headerHeight.value + 22;

			const { scrollHeight } = event.target;

			const scrolled = (scrollTop*100)/scrollHeight;
			progressBar.value.style.width = `${scrolled}%`;
		};


		const loadBlog = () => {
			if (!props.slug) {
				return;
			}
			getBlogBySlug(props.slug).then((blog) => {
				post.value = blog;
				let title = post.value?.title;
				if (!title) {
					return;
				}
				title = 'Greeve Blog - ' + title;
				getSeoManager().updateSeo(title, post.value?.contentAsText ?? '', post.value?.keywordList.join(', ') ?? '', post.value?.header_banner);
				const anchor = getAnchorFromUrl();
				if (anchor) {
					scrollToElement(anchor, 0, 400, 'center', 'nearest', 2);
				}
			});
		}

		onBeforeMount(() => {
			loadBlog();
			const progressBarElement = document.getElementById('progressBar');
			if (progressBarElement) {
				progressBar.value = progressBarElement;
			}
		});

		return {
			post,
			onScroll,
			progressBar,
			scrollToElement,
		};
	},
});

