
import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {SearchResultList} from '@/greeve/search/search_result_list.type';
import {SearchResult} from '@/greeve/search/search_result.type';
import useToastMessage from '@/composable/core/useToastMessage';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/translation/useTranslation';
import LoadingLineAnimation from '@/components/animations/LoadingLineAnimation.vue';
import {RouteLocationNormalizedLoaded, useRoute} from 'vue-router';
import * as icons from 'ionicons/icons';
import TextInput, {InputMode} from '@/components/inputs/TextInput.vue';
import {Bars3Icon, BookmarkIcon} from '@heroicons/vue/24/outline';
import LoadingLineBlurAnimation from '@/components/animations/LoadingLineBlurAnimation.vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import {Base64} from '@/greeve/core/encoding';
import useAssistant from '@/composable/greeve/useAssistant';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import useAuth from '@/composable/auth/useAuth';
import {SearchItemAuthorization} from '@/greeve/search/authorization/search_item_authorization.type';
import useAssistantFactory from '@/composable/greeve/useAssistantFactory';
import {
	GreeveSearchItemStateInterface,
	GreeveSearchItemSubTypeInterface,
} from '@/greeve/search/item/search_item.interface';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {IonContent, IonRefresher, IonRefresherContent} from '@ionic/vue';
import OutputTag from '@/components/outputs/OutputTag.vue';
import useCustomStore from '@/composable/custom/useCustomStore';
import InfoCard from '@/components/modal/InfoCard.vue';
import OutputContainer from '@/components/assistant/Output/OutputContainer.vue';
import PaywallOverlay from '@/components/modal/PaywallOverlay.vue';
import ActionBanner from '@/components/modal/ActionBanner.vue';
import useUser from '@/composable/greeve/useUser';
import {QuotaType} from '@/greeve/user/quota/quota.interface';
import {AudioVoice} from '@/greeve/search/item/type/subtype/search_item_text_to_speech.type';
import {ImageSize} from '@/greeve/search/item/type/subtype/search_item_image.type';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';
import {SearchItemUploadInteraction} from '@/greeve/search/item/type/search_item_upload_interaction.type';
import {AbstractSearchItem} from '@/greeve/search/item/abstract_search_item.type';
import {SearchItemTranscribeAudio} from '@/greeve/search/item/type/subtype/search_item_transcribe_audio.type';

export const enum ChatType {
	CHAT = 'chat',
	IMAGE = 'image',
	TRANSCRIBE = 'transcribe',
	TEXT_TO_SPEECH = 'text_to_speech',
}

export default defineComponent({
	name: 'ChatPage',
	components: {
		ActionBanner,
		PaywallOverlay,
		OutputContainer,
		InfoCard,
		Bars3Icon,
		OutputTag,
		// CardDefault,
		LoadingLineBlurAnimation,
		TextInput,
		LoadingLineAnimation, BookmarkIcon,
		IonRefresher, IonRefresherContent,
		//MagnifyingGlassIcon
		IonContent,
	},
	props: {
		groupChanged: {
			type: Number,
		},
	},
	emits: ['openSidebar'],
	setup() {
		const {isAuthenticated} = useAuth();
		const {headerHeight} = useHeaderHeight();
		const {setMobileMenuSidebar, isMobileDevice} = useCustomStore();
		const {t} = useTranslation();
		const {getQuotas, initQuotas} = useUser();
		const isLoading = ref(true);
		const isTextInputDisabled = ref(false);
		const searchValue = ref('');
		const searchResult: SearchResult | any = ref('');
		const searchResultList: SearchResultList | any = ref(new SearchResultList([]));
		const {openToast} = useToastMessage();
		const {scrollToElement, scrollToTop} = useSystem();
		const filterOnlyPinnedItems = ref(false);
		const lowCreditsRedirectLink = ref('');
		const hasLowCredits = ref(false);
		const inputMode = ref<InputMode>(InputMode.DEFAULT);

		const route = useRoute();
		const groupUuidByUrl = ref('');
		const groupReferenceByUrl = ref('');
		const {
			hasEnoughQuota,
			createAuthorizationItem,
			createEmptySearchItem_by_AuthorizationItem,
			createEmptyUploadInteractionItem,
			createEmptySearchItem,
			createImage,
			createSpeechByText,
			getAssistantStreamApi,
			addSearchItem,
			updateSearchItemResponse,
			updateSearchItemState,
			updateSearchItemUploadInteractionMediaFileInfo,
			updateSearchItemSubType,
			updateSearchItemDefaultByItem,
			retryItem,
			deleteSearchItem,
			getGroupByUuid,
			getGroupByUuidComputed,
			getItemByUuid,
			searchGroups,
			initSearchItems_by_Group,
			initSearchGroup_Uuid_Reference,
			createImageVariation,
			createSpeechToText,
			editImage,
			generateGroupName,
			initSearchItemByUuids,
		} = useAssistant();

		const selectedGroup = ref<SearchGroup | undefined>();
		const selectedChatType = ref<ChatType>(ChatType.CHAT);
		const hdImage = ref(false);
		const imageSize = ref<ImageSize|undefined>();
		const audioVoice = ref<AudioVoice|undefined>();
		const uploadInteractionItem = ref<SearchItemUploadInteraction|undefined>();

		const scrollToResponse = ref(false);
		const isBeforeEndEventTriggered = ref(false);
		const dataCounter = ref(0);
		const accumulatedData = ref<string[]>([]);
		const onEventTimerId = ref();

		const autoCheckPendingItemsIntervalId = ref();
		const autoCheckPendingItemsIntervalCount = ref(0);

		const isOnlySendButtonVisible = computed(() => {
			const allowedTypes = [GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT];
			return (uploadInteractionItem.value && allowedTypes.includes(uploadInteractionItem.value?.subType));
		});

		const hasUploadInteractionValidFile = computed(() => {
			return (uploadInteractionItem.value && uploadInteractionItem.value?.mediaFileInfo);
		});

		const getUploadInteractionSubTypeForTranslation = computed(() => {
			switch (uploadInteractionItem.value?.subType) {
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
					return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION;
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
					return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT;
				default:
					return 'default';
			}
		});

		function clearTextInput() {
			searchValue.value = '';
		}

		function resetSearch() {
			searchValue.value = '';
			try {
				initGroup(true, true);
			} catch (error) {
				console.error(error);
			}
		}

		function handleRefresh(event: CustomEvent | any) {
			resetSearch();
			setTimeout(() => {
				event.target.complete();
			}, 600);
		}

		function setSelectedInputType(type: ChatType, size: ImageSize|undefined = undefined, hd = false, voice: AudioVoice|undefined = undefined) {
			selectedChatType.value = type;
			imageSize.value = size;
			hdImage.value = hd;
			audioVoice.value = voice;
		}

		async function createImage_by_Prompt(
				searchGroupId: number, searchGroupUuid: string, prompt: string, searchGroupReference: string | null = null) {
			const emptyImagSearchItem = createEmptySearchItem(searchGroupId, prompt,
					GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE);
			addSearchItem(emptyImagSearchItem);
			//TODO add model
			return createImage(prompt, searchGroupUuid, searchGroupReference, false, imageSize.value, hdImage.value).then((searchItemImage) => {
				updateSearchItemDefaultByItem(emptyImagSearchItem, searchItemImage);
				updateGroupNameIfEmpty(prompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE);
				scrollToElement('response-' + searchItemImage.uuid, 40, 500);
			});
		}

		async function createAssistantImageVariation(mediaFileInfo: MediaFileInfo, searchGroupId: number, searchGroupUuid: string, prompt: string, searchGroupReference: string | null = null) {
			// const emptyImagSearchItem = createEmptySearchItem(searchGroupId, prompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION);
			// addSearchItem(emptyImagSearchItem);
			const interactionSearchItem = uploadInteractionItem.value;
			if (!interactionSearchItem) {
				throw new Error('Invalid Call!');
			}
			return createImageVariation(mediaFileInfo, searchGroupUuid, searchGroupReference, false, imageSize.value, hdImage.value).then((searchItemImage) => {
				updateSearchItemDefaultByItem(interactionSearchItem, searchItemImage);
				// updateGroupNameIfEmpty(prompt);
				scrollToElement('response-' + searchItemImage.uuid, 40, 500);
			});
		}

		async function createAssistantSpeechToText(mediaFileInfo: MediaFileInfo, searchGroupId: number, searchGroupUuid: string, prompt: string|null = null, searchGroupReference: string | null = null) {
			const interactionSearchItem = uploadInteractionItem.value;
			if (!interactionSearchItem) {
				throw new Error('Invalid Call!');
			}
			return createSpeechToText(mediaFileInfo, searchGroupUuid, searchGroupReference, false, true).then((searchItem) => {
				updateSearchItemDefaultByItem(interactionSearchItem, searchItem);
				let updateGroupPrompt: string|null|undefined = prompt;
				if (searchItem.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT) {
					const transcribeAudioItem = (searchItem as SearchItemTranscribeAudio);
					updateGroupPrompt = transcribeAudioItem.getRawText(500);
				}
				if (searchItem.isInProgress()) {
					registerChatBackgroundRunner()
				}
				if (!updateGroupPrompt) {
					updateGroupPrompt = undefined;
				}
				updateGroupNameIfEmpty(updateGroupPrompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT);
				scrollToElement('response-' + searchItem.uuid, 40, 500);
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		async function editAssistantImage(mediaFileInfo: MediaFileInfo, prompt: string, negativeText: string|null, searchGroupId: number, searchGroupUuid: string, searchGroupReference: string | null = null) {
			// const emptyImagSearchItem = createEmptySearchItem(searchGroupId, prompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION);
			// addSearchItem(emptyImagSearchItem);
			const interactionSearchItem = uploadInteractionItem.value;
			if (!interactionSearchItem) {
				throw new Error('Invalid Call!');
			}
			return editImage(mediaFileInfo,prompt, negativeText, searchGroupUuid, searchGroupReference, false, imageSize.value, hdImage.value).then((searchItemImage) => {
				updateSearchItemDefaultByItem(interactionSearchItem, searchItemImage);
				scrollToElement('response-' + searchItemImage.uuid, 40, 500);
			});
		}

		async function createSpeech_by_Prompt(
				searchGroupId: number, searchGroupUuid: string, prompt: string, searchGroupReference: string | null = null) {
			const emptyTextToSpeechSearchItem = createEmptySearchItem(searchGroupId, prompt,
					GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH);
			addSearchItem(emptyTextToSpeechSearchItem);
			return createSpeechByText(prompt, searchGroupUuid, searchGroupReference, false, audioVoice.value).then((searchItemTextToSpeech) => {
				updateSearchItemDefaultByItem(emptyTextToSpeechSearchItem, searchItemTextToSpeech);
				scrollToElement('response-' + searchItemTextToSpeech.uuid, 0, 500);
			});
		}

		function getGreeveSubType_by_Type(type: ChatType): GreeveSearchItemSubTypeInterface {
			switch (type) {
				case ChatType.CHAT:
					return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT;
				case ChatType.IMAGE:
					return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE;
				case ChatType.TEXT_TO_SPEECH:
					return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH;
				case ChatType.TRANSCRIBE:
					return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT;
				default:
					throw new Error(`Invalid type ${type}`);
			}
		}

		async function addNewUploadPrompt(subType: GreeveSearchItemSubTypeInterface) {
			const groupId = selectedGroup.value?.id;
			if (!groupId) {
				openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
				return;
			}
			inputMode.value = InputMode.UPLOAD;
			uploadInteractionItem.value = createEmptyUploadInteractionItem(groupId, '', subType);
			if (!uploadInteractionItem.value) {
				openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
				return;
			}
			addSearchItem(uploadInteractionItem.value);
			scrollToElement('prompt-' + uploadInteractionItem.value?.uuid, 0, 350);
		}

		async function cancelNewUploadPrompt(itemUuid: string) {
			inputMode.value = InputMode.DEFAULT;
			const uploadItem = getItemByUuid(itemUuid);
			if (uploadItem) {
				deleteSearchItem(uploadItem);
				uploadInteractionItem.value = undefined;
			}
			isTextInputDisabled.value = false;
		}

		async function uploadFinished(itemUuid: string|undefined, mediaFileInfo: MediaFileInfo) {
			if (!itemUuid) {
				return;
			}
			isTextInputDisabled.value = false;
			if (uploadInteractionItem.value?.uuid === itemUuid) {
				updateSearchItemUploadInteractionMediaFileInfo(uploadInteractionItem.value, mediaFileInfo);
			}
		}

		async function updateInteractionType(itemUuid: string|undefined, subType: GreeveSearchItemSubTypeInterface) {
			if (!itemUuid) {
				return;
			}
			isTextInputDisabled.value = false;
			if (uploadInteractionItem.value?.uuid === itemUuid && subType && uploadInteractionItem.value?.subType !== subType) {
				updateSearchItemSubType(uploadInteractionItem.value, subType);
			}
		}

		function streamOnEvent(searchItemNew: AbstractSearchItem) {
			return async (data: string) => {
				if (isBeforeEndEventTriggered.value) {
					return;
				}
				if (!scrollToResponse.value && data && data.length > 0) {
					scrollToResponse.value = true;
					updateSearchItemResponse(searchItemNew, data).then((result) => {
						if (result) {
							searchItemNew = result;
						}
					});
					setTimeout(() => {
						scrollToElement('response-' + searchItemNew.uuid, 0, 500);
					}, 50);
				} else {
					dataCounter.value++;
					// Accumulate the data
					accumulatedData.value.push(data);
					// Check if the counter reaches the threshold (3-4 entries)
					if (!onEventTimerId.value && accumulatedData.value && dataCounter.value >= 3 && ![GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_DONE, GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_ERROR].includes(searchItemNew.state)) {
						onEventTimerId.value = setTimeout(() => {
							const accumulatedDataString = accumulatedData.value.join('');
							updateSearchItemResponse(searchItemNew, accumulatedDataString).then((result) => {
								if (result) {
									searchItemNew = result;
								}
							});
							// Reset the counter and accumulated data array
							dataCounter.value = 0;
							accumulatedData.value = [];
							clearTimeout(onEventTimerId.value);
							onEventTimerId.value = undefined;
						}, 50);
						// Process accumulated data
					}
				}
				if (searchItemNew.state !== GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_IN_PROGRESS &&
						searchItemNew.state !== GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_DONE) {
					updateSearchItemState(searchItemNew, GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_IN_PROGRESS);
				}
			};
		}

		function streamOnInteractionRequired() {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			return async (data: string) => {
				//TODO
			};
		}

		function streamOnBeforeEndEvent(searchItemNew: AbstractSearchItem) {
			return (data: string) => {
				if (data) {
					isBeforeEndEventTriggered.value = true;
					dataCounter.value = 0;
					accumulatedData.value = [];
					const itemData = JSON.parse(data);
					const resultItem = useAssistantFactory().getSearchItemFactory().createSearchItemByResponse(itemData);
					updateSearchItemDefaultByItem(searchItemNew, resultItem);
					scrollToElement('response-' + resultItem.uuid, 0, 500);
				}
			};
		}

		function streamOnError(searchItemNew: AbstractSearchItem, timeoutId: number) {
			return (data: string) => {
				console.error('ERROR: ' + data);
				updateSearchItemState(searchItemNew, GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_ERROR);
				updateSearchItemResponse(searchItemNew, data);
				isLoading.value = false;
				clearTimeout(timeoutId);
				openToast(t('toast.errorDefault'), 'danger', 'bottom', true, 12000, undefined, true);
			};
		}

		function updateGroupNameIfEmpty(prompt: string|undefined = undefined, subType: GreeveSearchItemSubTypeInterface|undefined = undefined) {
			if (selectedGroup.value && selectedGroup.value.uuid && selectedGroup.value?.search_items?.length ===
					1 && (selectedGroup.value?.name?.length === 0 || selectedGroup.value?.getName()?.toLowerCase() ===
							'new')) {
				generateGroupName(selectedGroup.value?.uuid, prompt, false, subType);
			}
		}

		function streamOnEnd(timeoutId: number) {
			return async () => {
				updateGroupNameIfEmpty();
				isLoading.value = false;
				clearTimeout(timeoutId);
				if (onEventTimerId.value) {
					clearTimeout(onEventTimerId.value);
					onEventTimerId.value = undefined;
				}
				initQuotas(true).then(() => {
					calculateLowQuotaBanner(selectedChatType.value);
				});
			};
		}

		function handleStreamError(error: any, timeoutId: number) {
			let errorMessage = t('toast.errorDefault');
			if (error.status === 429) {
				errorMessage = t('toast.errorMaximum');
			}
			openToast(errorMessage, 'danger', 'bottom', true, 12000, undefined, true);
			clearTimeout(timeoutId);
			isLoading.value = false;
			calculateLowQuotaBanner(selectedChatType.value);
			console.error(error);
		}

		async function createChatStream(search: string, timeoutId: number) {
			return createAuthorizationItem(search, false, selectedGroup.value?.uuid, selectedGroup.value?.reference).
			then((response: SearchItemAuthorization | undefined) => {
				try {
					if (!response) {
						openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
						return;
					}
					let searchItemNew = createEmptySearchItem_by_AuthorizationItem(response,
							GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT);
					const groupId = searchItemNew.search_item_group_id;
					if (!groupId) {
						throw Error('Invalid Group!');
					}
					addSearchItem(searchItemNew);
					clearTextInput();
					scrollToElement('prompt-' + searchItemNew.uuid, 0, 50);
					const hash = getHashByItemUuid(response.uuid);
					const params = {
						'hash': hash,
						'search_item_reference': response.reference,
						'search_group_id': response?.search_item_group_id,
					};
					scrollToResponse.value = false;
					isBeforeEndEventTriggered.value = false;
					dataCounter.value = 0;
					accumulatedData.value = [];

					getAssistantStreamApi().chatStream(
							params,
							streamOnEvent(searchItemNew),
							streamOnInteractionRequired(),
							streamOnBeforeEndEvent(searchItemNew),
							streamOnError(searchItemNew, timeoutId),
							streamOnEnd(timeoutId),
					);
				} catch (error: Error | any) {
					handleStreamError(error, timeoutId);
				}
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		async function correctTextStream(search: string, timeoutId: number) {
			return createAuthorizationItem(search, false, selectedGroup.value?.uuid, selectedGroup.value?.reference).
			then((response: SearchItemAuthorization | undefined) => {
				try {
					if (!response) {
						openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
						return;
					}
					let searchItemNew = createEmptySearchItem_by_AuthorizationItem(response,
							GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT);
					const groupId = searchItemNew.search_item_group_id;
					if (!groupId) {
						throw Error('Invalid Group!');
					}
					addSearchItem(searchItemNew);
					clearTextInput();
					scrollToElement('prompt-' + searchItemNew.uuid, 0, 50);
					const hash = getHashByItemUuid(response.uuid);
					const params = {
						'hash': hash,
						'search_item_reference': response.reference,
						'search_group_id': response?.search_item_group_id,
					};
					scrollToResponse.value = false;
					isBeforeEndEventTriggered.value = false;
					dataCounter.value = 0;
					accumulatedData.value = [];

					getAssistantStreamApi().correctTextStream(
							params,
							streamOnEvent(searchItemNew),
							streamOnInteractionRequired(),
							streamOnBeforeEndEvent(searchItemNew),
							streamOnError(searchItemNew, timeoutId),
							streamOnEnd(timeoutId),
					);
				} catch (error: Error | any) {
					handleStreamError(error, timeoutId);
				}
			});
		}

		async function createVisionStream(search: string, mediaFileInfo: MediaFileInfo, timeoutId: number) {
			return createAuthorizationItem(search, false, selectedGroup.value?.uuid, selectedGroup.value?.reference).
			then((response: SearchItemAuthorization | undefined) => {
				try {
					if (!response) {
						openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
						return;
					}
					let searchItemNew = createEmptySearchItem_by_AuthorizationItem(response,
							GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT);
					const groupId = searchItemNew.search_item_group_id;
					if (!groupId) {
						throw Error('Invalid Group!');
					}
					addSearchItem(searchItemNew);
					clearTextInput();
					scrollToElement('prompt-' + searchItemNew.uuid, 0, 50);
					const hash = getHashByItemUuid(response.uuid);
					const params = {
						'hash': hash,
						'search_item_reference': response.reference,
						'search_group_id': response?.search_item_group_id,
						'media_file_info': mediaFileInfo.toJson(),
					};
					scrollToResponse.value = false;
					isBeforeEndEventTriggered.value = false;
					dataCounter.value = 0;
					accumulatedData.value = [];

					return getAssistantStreamApi().visionStream(
							params,
							streamOnEvent(searchItemNew),
							streamOnInteractionRequired(),
							streamOnBeforeEndEvent(searchItemNew),
							streamOnError(searchItemNew, timeoutId),
							streamOnEnd(timeoutId),
					);
				} catch (error: Error | any) {
					handleStreamError(error, timeoutId);
				}
			});
		}

		async function searchPrompt(search = '', emptyPromptAllowed = false) {
			if (!search.length && !emptyPromptAllowed) {
				return;
			}

			if (isLoading.value) {
				//TODO show message unique with info that a process is currently running...please wait
				return;
			}

			isLoading.value = true;

			const timeoutFactor = inputMode.value === InputMode.UPLOAD ? 3 : 1;
			const timeoutId = setTimeout(() => {
				isLoading.value = false;
			}, (85000 * timeoutFactor));

			if ((!useAuth().isAuthenticated || !selectedGroup.value?.id) &&
					(!selectedGroup.value?.uuid || !selectedGroup.value?.reference)) {
				await openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
				clearTimeout(timeoutId);
				isLoading.value = false;
				return;
			}

			if (inputMode.value !== InputMode.UPLOAD  && !await hasEnoughQuota(getGreeveSubType_by_Type(selectedChatType.value), search)) {
				await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
				clearTimeout(timeoutId);
				isLoading.value = false;
				return;
			}

			try {
				if (inputMode.value === InputMode.UPLOAD) {
					const itemUuid = uploadInteractionItem.value?.uuid;
					if (!itemUuid) {
						await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
						clearTimeout(timeoutId);
						isLoading.value = false;
						return;
					}
					if (!uploadInteractionItem.value) {
						await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
						clearTimeout(timeoutId);
						isLoading.value = false;
						return;
					}
					if (!uploadInteractionItem.value?.mediaFileInfo?.externalFileUrl) {
						await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
						clearTimeout(timeoutId);
						isLoading.value = false;
						return;
					}
					if (uploadInteractionItem.value?.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION) {
						createAssistantImageVariation(uploadInteractionItem.value?.mediaFileInfo, selectedGroup.value?.id, selectedGroup.value?.uuid, search, selectedGroup.value?.reference).then(() => {
							isLoading.value = false;
							clearTextInput();
							inputMode.value = InputMode.DEFAULT;
							uploadInteractionItem.value = undefined;
							isTextInputDisabled.value = false;
							calculateLowQuotaBanner(selectedChatType.value);
							clearTimeout(timeoutId);
						});
					} else if (uploadInteractionItem.value?.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION) {
						createVisionStream(search, uploadInteractionItem.value?.mediaFileInfo, timeoutId).then(() => {
							cancelNewUploadPrompt(itemUuid);
							inputMode.value = InputMode.DEFAULT;
							uploadInteractionItem.value = undefined;
							isTextInputDisabled.value = false;
							clearTimeout(timeoutId);
						});
					} else if (uploadInteractionItem.value?.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT) {
						return createAssistantSpeechToText(uploadInteractionItem.value?.mediaFileInfo, selectedGroup.value?.id, selectedGroup.value?.uuid, search, selectedGroup.value?.reference).then(() => {
							isLoading.value = false;
							clearTextInput();
							inputMode.value = InputMode.DEFAULT;
							uploadInteractionItem.value = undefined;
							isTextInputDisabled.value = false;
							calculateLowQuotaBanner(selectedChatType.value);
							cancelNewUploadPrompt(itemUuid);
							clearTimeout(timeoutId);
						});
					}
					return;
				}

				if (selectedChatType.value === ChatType.IMAGE) {
					createImage_by_Prompt(selectedGroup.value?.id, selectedGroup.value?.uuid, search,
							selectedGroup.value?.reference).then(() => {
						isLoading.value = false;
						clearTextInput();
						calculateLowQuotaBanner(selectedChatType.value);
						clearTimeout(timeoutId);
					});
				}

				if (selectedChatType.value === ChatType.TEXT_TO_SPEECH) {
					createSpeech_by_Prompt(selectedGroup.value?.id, selectedGroup.value?.uuid, search,
							selectedGroup.value?.reference).then(() => {
						isLoading.value = false;
						clearTextInput();
						calculateLowQuotaBanner(selectedChatType.value);
						clearTimeout(timeoutId);
					});
				}

				if (selectedChatType.value === ChatType.CHAT) {
					createChatStream(search, timeoutId);
				}
			} catch (e) {
				console.error(e);
				await openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
				clearTimeout(timeoutId);
				isLoading.value = false;
			}
		}

		function registerChatBackgroundRunner() {
			if (!selectedGroup.value || autoCheckPendingItemsIntervalId.value) {
				stopChatBackgroundRunner();
				return;
			}

			const searchItemList = selectedGroup.value?.search_items;
			if (!searchItemList) {
				return;
			}

			const pendingSearchItemUuids = searchItemList.getInProgressUuids();
			if (!pendingSearchItemUuids || pendingSearchItemUuids.length === 0) {
				stopChatBackgroundRunner();
				return;
			}

			//TODO emergency stop after 60*15min
			autoCheckPendingItemsIntervalId.value = setInterval(() => {
				const pendingSearchItemUuids = searchItemList.getInProgressUuids();
				if (!pendingSearchItemUuids || pendingSearchItemUuids.length === 0) {
					stopChatBackgroundRunner();
					return;
				}

				if (autoCheckPendingItemsIntervalCount.value > 100) {
					stopChatBackgroundRunner();
					return;
				}
				initSearchItemByUuids(pendingSearchItemUuids, selectedGroup.value?.id);
				autoCheckPendingItemsIntervalCount.value++;
			}, 8000)
		}

		function stopChatBackgroundRunner() {
			if (!autoCheckPendingItemsIntervalId.value) {
				return;
			}

			clearInterval(autoCheckPendingItemsIntervalId.value);
			autoCheckPendingItemsIntervalId.value = undefined;
			autoCheckPendingItemsIntervalCount.value = 0;
		}

		async function initGroup(forceRefresh = false, forceGroupRefresh = false) {
			if (groupUuidByUrl.value) {
				selectedGroup.value = getGroupByUuid(groupUuidByUrl.value);
				if (!selectedGroup.value || forceGroupRefresh) {
					selectedGroup.value = await initSearchGroup_Uuid_Reference(groupUuidByUrl.value, groupReferenceByUrl.value);
				}
				if (selectedGroup.value && (selectedGroup.value.search_items?.length === 0 || forceRefresh)) {
					return initSearchItems_by_Group(selectedGroup.value);
				}
			}
		}

		async function loadChatByRoute(route: RouteLocationNormalizedLoaded, force = false) {
			const params = route.params;
			if (!params || !params.name || !params.uuid || !params.reference || !isAuthenticated) {
				isLoading.value = false;
				return;
			}

			groupUuidByUrl.value = Base64.decode(params.uuid.toString());
			groupReferenceByUrl.value = params.reference.toString();

			initGroup(force).then(() => {
				registerChatBackgroundRunner();
				isLoading.value = false;
			});
		}

		function getHashByItemUuid(itemUuid: string) {
			return Base64.encode(JSON.stringify({'search_item_uuid': itemUuid, 'user_id': useUser().user.value.id}));
		}

		function regenerateItem(itemUuid: string, itemSubType: GreeveSearchItemSubTypeInterface) {
			isLoading.value = true;
			const timeoutId = setTimeout(() => {
				isLoading.value = false;
			}, 35000);
			const errorSearchItem = getItemByUuid(itemUuid);
			if (!errorSearchItem) {
				return;
			}
			if (itemSubType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT) {
				try {
					const hash = getHashByItemUuid(itemUuid);
					const params = {
						'hash': hash,
					};
					scrollToResponse.value = false;
					isBeforeEndEventTriggered.value = false;
					dataCounter.value = 0;
					accumulatedData.value = [];

					getAssistantStreamApi().retryItemStream(
							params,
							streamOnEvent(errorSearchItem),
							streamOnInteractionRequired(),
							streamOnBeforeEndEvent(errorSearchItem),
							streamOnError(errorSearchItem, timeoutId),
							streamOnEnd(timeoutId),
					);
				} catch (error: Error | any) {
					handleStreamError(error, timeoutId);
				}
			} else {
				retryItem(itemUuid, false).then((resultItem) => {
					// deleteSearchItem(errorSearchItem);
					updateSearchItemDefaultByItem(errorSearchItem, resultItem);
					isLoading.value = false;
				})
			}
		}

		function calculateLowQuotaBanner(chatType: ChatType) {
			if (!isAuthenticated.value) {
				return;
			}
			const minQuota = 1;
			let availableQuota = 0;
			let quotaType = null;
			if (chatType === ChatType.CHAT) {
				quotaType = QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_CHAT_CREDITS;
			} else if (chatType === ChatType.IMAGE) {
				quotaType = QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_IMAGE_CREDITS;
			}

			if (quotaType) {
				if (!getQuotas.value) {
					return;
				}
				availableQuota = getQuotas.value.getAvailableQuotaAmountByType(quotaType);
				if (availableQuota <= minQuota) {
					availableQuota = getQuotas.value.getAvailableQuotaAmountByType(QuotaType.QUOTA_TYPE_CREDITS);
					if (availableQuota <= minQuota) {
						lowCreditsRedirectLink.value = '/profile/credits?tab=' + quotaType;
						hasLowCredits.value = true;
					}
				} else {
					hasLowCredits.value = false;
				}
			} else {
				hasLowCredits.value = false;
			}
		}

		watch(() => selectedChatType.value, (newSelectedChatType: ChatType) => {
			calculateLowQuotaBanner(newSelectedChatType);
		});

		watch(() => searchGroups.value,
				(newGroups: SearchGroupList | undefined, oldGroups: SearchGroupList | undefined) => {
					if (newGroups !== oldGroups && selectedGroup.value) {
						const newSelectedGroup = newGroups?.getItemByUuId(selectedGroup.value?.uuid, false);
						if (newSelectedGroup !== selectedGroup.value) {
							selectedGroup.value = newSelectedGroup;
						}
					}
				});

		const showNameOnNativeDevice = ref(true);
		let lastScrollTop = 0;
		const buffer = 30;

		const handleScroll = (event: any) => {
			if (!isMobileDevice()) {
				return;
			}
			const currentScrollTop = event.detail.scrollTop;

			// Check if we've scrolled down past the buffer distance
			if (currentScrollTop > lastScrollTop + buffer) {
				// Scrolling down
				showNameOnNativeDevice.value = false;
			}
			// Check if we've scrolled up past the buffer distance
			else if (currentScrollTop < lastScrollTop - buffer) {
				// Scrolling up
				showNameOnNativeDevice.value = true;
			}

			// Update lastScrollTop, but not when we're at the top
			lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
		};

		onMounted(() => {
			loadChatByRoute(route, true);
			calculateLowQuotaBanner(ChatType.CHAT);
			scrollToTop();
			// console.log("Total localStorage usage: " + useSystem().calculateTotalLocalStorageUsage() + " kb");
		});

		onBeforeUnmount(() => {
			stopChatBackgroundRunner();
		});

		return {
			icons,
			t,
			searchValue,
			searchResult,
			isLoading,
			searchResultList,
			handleRefresh,
			clearTextInput,
			headerHeight,
			searchPrompt,
			selectedGroup,
			selectedChatType,

			getGroupByUuidComputed,
			groupUuidByUrl,
			groupReferenceByUrl,
			searchGroups,
			setSelectedInputType,
			filterOnlyPinnedItems,
			setMobileMenuSidebar,
			isAuthenticated,
			route,
			lowCreditsRedirectLink,
			hasLowCredits,
			handleScroll,
			showNameOnNativeDevice,
			regenerateItem,
			addNewUploadPrompt,
			cancelNewUploadPrompt,
			uploadFinished,
			inputMode,
			isTextInputDisabled,
			updateInteractionType,
			isOnlySendButtonVisible,
			getUploadInteractionSubTypeForTranslation,
			hasUploadInteractionValidFile
		};
	},
});

