
import {defineComponent, ref, watch} from 'vue';
import useAlerts from "@/composable/core/useAlert";
import {CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon, XMarkIcon} from '@heroicons/vue/20/solid'

export default defineComponent({
	name: 'BaseAlertComponent',
	components: {
		XCircleIcon, CheckCircleIcon,
		XMarkIcon,
		ExclamationTriangleIcon
	},
	props: {
		id: {
			type: String,
			default: null,
		},
		alertPadding: {
			type: String,
			// default: "pt-4",
			default: "pt-4 fixed w-full p-4",
		},
		alertPosition: {
			type: String,
			default: "p-4 ",
		},
		alertType: {
			type: String,
			default: "error",
		},
		title: {
			type: String,
			default: "",
		},
		message: {
			type: String,
			default: "",
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		showIcon: {
			type: Boolean,
			default: true,
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		showAsList: {
			type: Boolean,
			default: false,
		},
		cssClass: {
			type: String,
			default: "w-full relative sticky ",
		},
		useAlertListMessageFromStorage: {
			type: Boolean,
			default: false,
		},
		showAlert: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['closeAlert'],
	setup(props, {emit}) {
		const {getAllAlerts, removeAlertByType} = useAlerts();
		const alertId = ref(props.id);
		const alertMessageList = ref();
		const alertTitle = ref(props.title);
		const alertMessage = ref(props.message);
		const alertUseAlertListMessageFromStorage = ref(props.useAlertListMessageFromStorage);
		const hasErrorMessagesToShow = ref(false);
		const test = ref();

		function hasAlertMessageList(): boolean {
			if (!alertUseAlertListMessageFromStorage.value && alertMessage && alertMessage.value.length > 0) {
				return false;
			}
			return getAllAlerts.value !== undefined && getAllAlerts.value[props.alertType] && getAllAlerts.value[props.alertType].length > 0;
		}

		// function updateErrorMessageToShowCheck() {
		// 	hasErrorMessagesToShow.value = ((hasAlertMessageList() || alertTitle.value.length > 0 || alertMessage.value.length > 0) && props.showAlert) === true;
		// }

		function isError() {
			return props.alertType === "error";
		}

		function isSuccess() {
			return props.alertType === "success";
		}

		function isWarning() {
			return props.alertType === "warning";
		}

		function isSecondary() {
			return props.alertType === "secondary";
		}

		const closeAlert = (selectedId: any) => {
			//TODO check if delete element or just disable it
			if (selectedId) {
				const elementToRemove = document.getElementById(selectedId);
				if (elementToRemove) {
					alertTitle.value = "";
					alertMessage.value = "";
					if (alertUseAlertListMessageFromStorage.value) {
						removeAlertByType(props.alertType);
					}
				}
			} else {
				removeAlertByType(props.alertType);
			}
			hasErrorMessagesToShow.value = false;
			emit('closeAlert');
		};

		watch(() => props.message, (newMessage) => {
			if (newMessage && newMessage.length > 0) {
				alertMessage.value = newMessage;
			}
		});

		watch(() => props.title, (newTitle) => {
			if (newTitle && newTitle.length > 0) {
				alertTitle.value = newTitle;
			}
		});

		return {
			getAllAlerts,
			alertMessageList,
			hasAlertMessageList,
			alertId,
			alertTitle,
			alertMessage,
			closeAlert,
			isError,
			isSuccess,
			isWarning,
			isSecondary,
			hasErrorMessagesToShow,
			test,
		};
	}
})
