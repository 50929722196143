
import {defineComponent, ref} from 'vue';
import router from "@/router";

export default defineComponent({
	name: 'LinkButton',
	components: {},
	props: {
		buttonText: {
			type: String,
			default: "E-Mail"
		},
		buttonAlignment: {
			type: String,
			default: "items-center justify-center"
		},
		buttonWidth: {
			type: String,
			default: "w-full",
		},
		centerButton: {
			type: Boolean,
			default: true,
		},
		link: {
			type: String,
			default: "",
		},
		buttonColor: {
			type: String,
			default: 'btn-main-color default-btn',
		},
		textColor: {
			type: String,
			default: 'text-white',
		},
		textAlignment: {
			type: String,
			default: "text-center"
		},
		buttonHover: {
			type: String,
			default: 'hover:bg-bp',
		},
		buttonXPadding: {
			type: String,
			default: 'px-16',
		},
		buttonYPadding: {
			type: String,
			default: 'py-3',
		},
		customCss: {
			type: String,
			default: "",
		},
	},
	setup (props) {

		const redirectLink = ref(props.link);

		function redirectToLink()
		{
			if (redirectLink.value) {
				router.push(redirectLink.value);
			}
		}

		return {
			redirectToLink
		};
	}
})
