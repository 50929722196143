
import {IonPage, IonContent, IonRouterOutlet} from '@ionic/vue';
import {defineComponent} from 'vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';

export default defineComponent({
	name: 'BlogPage',
	components: {
		IonRouterOutlet,
		IonPage,
		IonContent,
		FooterBar,
	},
	setup() {
		const {headerHeight} = useHeaderHeight();

		return {headerHeight};
	},
});

