
import {defineComponent, onMounted} from 'vue';
import useBlog from '@/composable/blog/useBlog';

export default defineComponent({
	name: 'BlogListPage',
	components: {},
	setup() {
		const {blogs, initBlogs} = useBlog();

		onMounted(() => {
			initBlogs(true).then(() => {
				if (blogs.value) {
					blogs.value?.initSeo();
				}
			});
		});

		return {
			blogs,
		};
	},
});

